<template>
  <div>
    <el-input v-if="readonly" :value="inputValue" :size="size" style="width:100%"  ref="input" disabled/>
    <el-popover v-else
      ref="popover"
      trigger="manual"
      placement="bottom"
      @show="handleShowed"
      style="width:100%"
      popper-class="virtual_select_popover"
      v-clickoutside="()=>popoverVisible=false"
      :value="popoverVisible">
      <div ref="popoverContainer"
        v-loading="isLoading">
        <VirtualScrollList class="virtual_option_list"
          ref="refScroll"
          v-if="dataList[0] && popoverVisible"
          :size="34"
          style="width:100%"
          :onscroll="handleScrollToBottom"
          :remain="dataList.length >10?10:dataList.length">
          <div v-for="item in dataList"
            :id="item[option.key]"
            class="virtual_option_item"
            :key="item[option.key]"
            @click="handleChange(item)"
            :class="{selected:item[option.key] === value}">
            <span style="float: left">{{ item[option.label] }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px" v-if="option.desc">{{ getDesc(item)}}</span>
          </div>
        </VirtualScrollList>
          <div v-else-if="renderKey === 'customerMsg'" style="padding: 20px 15px 40px;">
            暂无数据，请点击<span @click="showHospitalDialog = true" style="color: #237FFA;cursor: pointer;">“新增”</span>按钮添加
          </div>
        <div v-else class="text-align-center ">无匹配数据</div>
      </div>
      <el-input
        :size="size"
        slot="reference"
        ref="input"
        :readonly="!filterable"
        :value="inputValue"
        @focus="handleFocus"
        style="width:100%"
        clearable
        @input="handleInput"
        :placeholder="defaultValue || placeholder"
      >
      </el-input>
    </el-popover>

    <!-- 新增医院 -->
    <AddHospitalDialog :show="showHospitalDialog" @close="close" />
  </div>
</template>
<script>
import VirtualScrollList from 'vue-virtual-scroll-list';
import Clickoutside from 'element-ui/src/utils/clickoutside';
import AddHospitalDialog from '@/components/addHospitalDialog';
import _ from 'lodash';
export default {
  name: 'VIRTUAL_SELECTOR',
  directives: {
    Clickoutside,
  },
  components: {
    VirtualScrollList,
    AddHospitalDialog,
  },
  props: {
    renderKey: {
      type: String,
      default: '',
    },
    value: {
      type: String || Array,
    },
    size: {
      type: String,
      default: 'small',
    },
    name: {
      type: String,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    optionList: {
      type: Array,
      default: ()=>[],
    },
    option: {
      type: Object,
      default: () => {
        return {
          key: 'id',
          label: 'name',
          desc: 'desc',
        };
      },
    },
    remote: {
      type: Boolean,
      default: false,
    },
    filterable: {
      type: Boolean,
      default: false,
    },
    remoteMethod: {
      type: Function,
      default: null,
    },
    loadMore: {
      type: Function,
      default: null,
    },
    placeholder: {
      type: String,
      default: '请输入',
    },
    allowCreate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inputValue () {
      if (this.popoverVisible) {
        return this.searchKeyword;
      } else {
        return this.defaultValue;
      }
    },
  },
  data () {
    return {
      popoverVisible: false,
      defaultValue: this.name,
      width: 100,
      searchKeyword: '',
      dataList: _.cloneDeep(this.optionList),
      showHospitalDialog: false,
    };
  },
  watch: {
    name: function (val) {
      if (val !== this.defaultValue) {
        this.defaultValue = val;
      }
    },
    popoverVisible () {
      if (!this.popoverVisible) {
        this.searchKeyword = '';
        if (this.remote) {
          this.dataList.splice(0, this.dataList.length);
        }
      }
    },
    optionList () {
      if (JSON.stringify(this.optionList) !== JSON.stringify(this.dataList)) {
        this.dataList = _.cloneDeep(this.optionList);
        if (!this.defaultValue && this.value) {
          const selected = this.dataList.find(t=>t[this.option.key] === this.value);
          if (selected) {
            this.defaultValue = selected[this.option.label];
          }
        }
        this.$nextTick(()=>{
          this.$refs.refScroll && this.$refs.refScroll.forceRender();
          this.$refs.popover && this.$refs.popover.updatePopper();
        });
      }
    },
  },
  methods: {
    close () {
      this.showHospitalDialog = false;
      this.handleSearch();
    },
    handleShowed () {
      this.handleSearch();
    },
    getDesc (i) {
      if (!this.option.desc) {
        return '';
      }
      if (typeof (this.option.desc) === 'function') {
        return this.option.desc(i);
      } else {
        return i[this.option.desc];
      }
    },
    handleFocus () {
      this.popoverVisible = true;
    },
    handleInput: _.debounce(function (val) {
      this.searchKeyword = val;
      if (this.popoverVisible) {
        if (this.allowCreate) {
          this.defaultValue = val;
          this.$emit('input', null);
          this.$emit('update:name', val);
          this.$emit('change', {val});
        }
        this.handleSearch();
      } else {
        this.defaultValue = '';
        this.$emit('input', null);
        this.$emit('update:name', null);
        this.$emit('change', {});
      }
    },
    100,
    ),
    handleChange (item) {
      this.popoverVisible = false;
      this.defaultValue = item[this.option.label];
      this.$emit('input', item[this.option.key]);
      this.$emit('update:name', item[this.option.label]);
      this.$emit('change', item);
    },
    calcPopoverWidth () {
      let rect = this.$refs.input.$el.getBoundingClientRect();
      this.width = rect.width;
    },
    handleSearch () {
      if (this.remote) {
        this.remoteMethod && this.remoteMethod(this.searchKeyword);
      } else {
        if (this.searchKeyword) {
          this.dataList = this.optionList.filter(t=>t[this.option.label].indexOf(this.searchKeyword) >= 0);
        } else {
          this.dataList = this.optionList;
        }
      }
    },
    handleScrollToBottom: _.debounce(function (event, param) {
      if (this.isLoading) {
        return;
      }
      const { offsetAll, offset } = param;
      if (offset > 0 && offset >= offsetAll) {
        this.loadMore && this.loadMore(this.searchKeyword);
      }
    }, 100),
  },
  mounted () {
    this.popperElm = this.$refs.popoverContainer;
    this.calcPopoverWidth();
    window.addEventListener('resize', this.calcPopoverWidth);
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.calcPopoverWidth);
  },
};

</script>

<style lang="scss" >
.virtual_select_popover{
  padding:12px 0;

  .virtual_option_list {
    width: 100%;
    padding: 2px 0;
    margin: 0;
    box-sizing: border-box;
    overflow-y: auto;
    .virtual_option_item {
      font-size: 14px;
      padding: 0 20px;
      display: flex;
      flex-direction: row;
      position: relative;
      color: #606266;
      height: 34px;
      line-height: 34px;
      box-sizing: border-box;
      cursor: pointer;

      &.selected{
        color: #409EFF;
        font-weight: 700
      }

      &>span{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
        &:first-child{
          flex: 2;
        }
      }

      &:hover {
        color: #409EFF;
        font-weight: 700
      }
    }
}
}

</style>
